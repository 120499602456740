import React, { Component } from 'react';

import {

  Header,

} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

export default class About extends Component {

  render() {


    return (
      <div className="Home">

        <Header as='h1'>About</Header>





      </div>

    );
  }
}

