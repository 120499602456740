import React, { Component } from 'react';
import {
    Card,
    Image,
} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import hiraganaImage from '../assets/a.png'

export default class HiraganaCard extends Component {

    render() {
        return (
            <Card centered>
                {/* <Image src={require('../assets/a.png')} wrapped ui={false} /> */}
                <Image src={hiraganaImage} wrapped ui={false} />
                <Card.Content>
                    <Card.Header>あ</Card.Header>
                    {/* <Card.Meta>
                        <span className='date'>Joined in 2015</span>
                    </Card.Meta> */}
                    <hr border-color = 'gray'/>
                    <Card.Description textAlign="left">
                        Looks like: an <u>apple</u> with a stem and two leaves
                        <hr border-color = 'gray'/>
                        Sounds like: "ahh" or the "a" in "<b>a</b>pple"
                        
                        
                    </Card.Description>

                </Card.Content>
                {/* <Card.Content extra>
                    <a><Icon name='user' />22 Friends</a>
                </Card.Content> */}
            </Card>
            // "hello"
        );
    }
}