import React, { Component } from 'react';

import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import {
  Input,

  Button,

  InputOnChangeData,
  Header,

} from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'
import HiraganaCard from '../components/HiraganaCard';
import axios from 'axios';
const config = require('../config.json');
const environment = process.env.NODE_ENV || 'development';
const environmentConfig = config[environment];

export default class Home extends Component {
  
  state = { activeItem: 'home' }
  email = '';

  onEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.email = data.value;
  }

   onSubscribeClick = async() => {
    let subscribeEndpoint = environmentConfig['apiGatewayEndpoint'] + '/subscribe';
    const response = await axios.put(subscribeEndpoint, {email: this.email});
    console.log(response);
    alert("Please check for an email from mail@dailyhiragana.com . You'll have to confirm your email to get the subscription started.");
    //TODO: handle any errors with subscribing
    //TODO: change alert message to either a modal or closable box 
  }

  render() {

    return (
      <div className="Home">

        <Header as='h1'>Learn one Hiragana character every day.</Header>


        <HiraganaCard />


        <Header as='h2'>We'll email you at noon every day.</Header>

        <Input placeholder='Email Address' onChange={this.onEmailAddressChange} />
        <Button onClick={this.onSubscribeClick} >Subscribe</Button>
      </div>

    );
  }
}

