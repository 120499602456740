import React, { Component } from 'react';
import logo from './assets/logo_transparent.png';
import './App.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import {
  Input,
  Menu,
  Button,
  ButtonProps,
  Grid,
  InputOnChangeData,
  Header,
  Card,
  Image,
  Icon,
  Container
} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import HiraganaCard from './components/HiraganaCard';
import Home from './containers/Home';
import About from './containers/About';

export default class App extends Component {
  state = { activeItem: 'home' }
  email = '';

  handleItemClick = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
    this.setState({ activeItem: data.name });
    console.log(data);
  }

  onEmailAddressChange = (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
    this.email = data.value;
  }

  onSubscribeClick = () => {

  }

  render() {
    const { activeItem } = this.state;

    return (
      <div className="App">



        <BrowserRouter
          basename={'/'}
        >
          <Menu>
            <Image src={logo}
              size='mini' />

            <Menu.Item
              header
              as={Link} to="/home"
              name='Daily Hiragana'
              active={activeItem == 'home'}
              onClick={this.handleItemClick}>
            </Menu.Item>
            <Menu.Item
              as={Link} to="/about"
              name='about'
              active={activeItem == 'about'}
              onClick={this.handleItemClick}>
            </Menu.Item>
          </Menu>
          <Switch>

            <Route path="/home">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/">
              <Home />
            </Route>

          </Switch>
        </BrowserRouter>


        <div className='ui segment'
          style={{ 'font': 'Lato', position: 'absolute', bottom: 0, width: '100%' }}
        >
          Codatia, LLC
        </div>
      </div >

    );
  }
}

